import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "field-pill-title" }
const _hoisted_2 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_3 = {
  key: 1,
  class: "text-muted"
}
const _hoisted_4 = {
  key: 2,
  class: "text-muted"
}
const _hoisted_5 = { class: "flex flex-wrap border rounded field-pill-section" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_field_pill = _resolveComponent("field-pill")

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys($data.fields), (field) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "field-pill-section-wrapper",
        key: field
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h4", null, [
            ($options.showReference(field))
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.__("Reference Document : ")), 1 /* TEXT */))
              : (field !== _ctx.__('Custom Functions'))
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.__("Linked Document : ")), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
            _createTextVNode(" " + _toDisplayString(_ctx.__($data.fields[field][0]["parent"])) + " ", 1 /* TEXT */),
            (
							'reference_label' in $data.fields[field][0] &&
							field !== _ctx.__('Custom Functions')
						)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                  _createTextVNode("(" + _toDisplayString(_ctx.__("Field")) + " ", 1 /* TEXT */),
                  _createElementVNode("i", null, _toDisplayString(_ctx.__($data.fields[field][0]["reference_label"])) + ")", 1 /* TEXT */)
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.fields[field], (f) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "field-pill-wrapper",
              key: f.fieldname
            }, [
              _createVNode(_component_field_pill, {
                label: f.label,
                fieldname: f.fieldname,
                fieldtype: f.fieldtype,
                parent: f.parent,
                reference: f.reference,
                function: f.function,
                selectedPills: $data.selectedPills
              }, null, 8 /* PROPS */, ["label", "fieldname", "fieldtype", "parent", "reference", "function", "selectedPills"])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}