frappe.ui.form.ControlAttachImage = class ControlAttachImage extends frappe.ui.form.ControlAttach {
	make_input() {
		super.make_input();

		let $file_link = this.$value.find(".attached-file-link");
		$file_link.popover({
			trigger: "hover",
			placement: "auto",
			content: () => {
				const escapedSrc = this.get_value().replace('"', "&quot;");
				return `<img src="${escapedSrc}" style="object-fit: contain;" width="100%" height="auto" />`;
			},
			html: true,
		});
	}

	get_preview_section() {
		// @dokos
		return `<div class="file-preview">
			<div class="file-icon flex">
				<img class="attached-file-preview border rounded m-auto" style="object-fit: cover; max-height: 512px;" onclick="window.open(this.src)" />
			</div>
		</div>`;
	}

	set_upload_options() {
		super.set_upload_options();
		this.upload_options.restrictions.allowed_file_types = ["image/*"];
	}
};
