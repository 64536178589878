
	frappe.templates['text_editor'] = `<div class="toolbar-container user-select-none">
	<span class="ql-formats">
		<select class="ql-header" data-toggle="tooltip" title="{{ header }}">
			<option value="1">{{ __("Heading 1") }}</option>
			<option value="2">{{ __("Heading 2") }}</option>
			<option value="3">{{ __("Heading 3") }}</option>
			<option selected="selected">{{ __("Normal") }}</option>
		</select>
	</span>
	<span class="ql-formats">
		<select class="ql-size" data-toggle="tooltip" title="{{ size }}">
			{% for ft in font_sizes %}
			<option value="{{ ft }}">{{ __(ft) }}</option>
			{% endfor %}
		</select>
	</span>
	<span class="ql-formats">
		<button class="ql-bold" data-toggle="tooltip" data-placement="bottom" title="{{ bold }}"></button>
		<button class="ql-italic" data-toggle="tooltip" data-placement="bottom" title="{{ italic }}"></button>
		<button class="ql-underline" data-toggle="tooltip" title="{{ underline }}"></button>
		<button class="ql-strike" data-toggle="tooltip" title="{{ strike }}"></button>
	</span>
	<span class="ql-formats">
		<button class="ql-blockquote" data-toggle="tooltip" title="{{ blockquote }}"></button>
		<button class="ql-code-block" data-toggle="tooltip" title="{{ codeblock }}"></button>
		<button class="ql-direction" value="rtl" data-toggle="tooltip" title="{{ direction }}"></button>
	</span>
	<span class="ql-formats">
		<button class="ql-link" data-toggle="tooltip" title="{{ link }}"></button>
		<button class="ql-image" data-toggle="tooltip" title="{{ image }}"></button>
	</span>
	<span class="ql-formats">
		<button class="ql-list" value="ordered" data-toggle="tooltip" title="{{ orderedlist }}"></button>
		<button class="ql-list" value="bullet" data-toggle="tooltip" title="{{ bulletlist }}"></button>
		<button class="ql-list" value="check" data-toggle="tooltip" title="{{ checklist }}">
			<svg viewbox="0 0 18 18">
				<line class="ql-stroke" x1="9" x2="15" y1="4" y2="4"></line>
				<polyline class="ql-stroke" points="3 4 4 5 6 3"></polyline>
				<line class="ql-stroke" x1="9" x2="15" y1="14" y2="14"></line>
				<polyline class="ql-stroke" points="3 14 4 15 6 13"></polyline>
				<line class="ql-stroke" x1="9" x2="15" y1="9" y2="9"></line>
				<polyline class="ql-stroke" points="3 9 4 10 6 8"></polyline>
			</svg>
		</button>
	</span>
	<span class="ql-formats">
		<select class="ql-align" data-toggle="tooltip" title="{{ align }}">
			<option selected="selected">{{ __("Left", null, "Text Editor") }}</option>
			<option value="center">{{ __("Center", null, "Text Editor") }}</option>
			<option value="right">{{ __("Right", null, "Text Editor") }}</option>
			<option value="justify">{{ __("Justify", null, "Text Editor") }}</option>
		</select>
	</span>
	<span class="ql-formats">
		<button class="ql-indent" value="-1" data-toggle="tooltip" title="{{ indent }}"></button>
		<button class="ql-indent" value="+1" data-toggle="tooltip" title="{{ indent }}"></button>
	</span>
	<span class="ql-formats">
		<select class="ql-table" data-toggle="tooltip" title="{{ table }}">
			<option value="insert-table">
			<option value="insert-row-above">
			<option value="insert-row-below">
			<option value="insert-column-right">
			<option value="insert-column-left">
			<option value="delete-row">
			<option value="delete-column">
			<option value="delete-table">
		</select>
	</span>
	<span class="ql-formats">
		<button class="ql-clean" data-toggle="tooltip" title="{{ clean }}"></button>
	</span>
	{% if showtemplate %}
	<span class="ql-formats">
		<button class="ql-template-blot" data-toggle="tooltip" title="{{ templateblot }}">
			{{ frappe.utils.icon("shortcut") }}
		</button>
	</span>
	{% endif %}
</div>`;
